export const changeBlockHTML = (data, targetId, value) => {
        
        if (data === null || data === undefined) {
          return null;
        }
      
        if (Array.isArray(data)) {
          // Если это массив, перебираем его элементы
          data.forEach(el => {
            changeBlockHTML(el, targetId, value)
          });
        } else if (typeof data === 'object') {
          // Если это объект, перебираем его свойства
          for(let el in data){
            if (Array.isArray(data[el])) {
                // Если это массив, перебираем его элементы
                data[el].forEach(el => {
                  changeBlockHTML(el, targetId, value)
                });
            }

            if(data[el] === targetId){
                data['html'] = value
            }
          }
        }
    return data
}