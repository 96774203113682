import { Route, Routes } from "react-router-dom";
import { Auth } from "./pages/user/auth/auth";
import { Main } from "./pages/main/main";
import './index.scss';
import './quill.css';

function App() {
  
  return (
    <div className="App">
      <Routes>
        <Route path="*" element={<Main/>}/>
        <Route path="/auth/" element={<Auth/>}/>
      </Routes>
    </div>
  );
}

export default App;
