import React from 'react';
import s from './moduleTextItem.module.scss';
import { useSelector } from 'react-redux';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/edit_icon.svg'
import { ReactComponent as Tick } from '../../../assets/icons/tick.svg'

export const ModuleTextItem = ({ data, setModal, langState, setLangState })=>{
    
    const langs = useSelector(store => store.main.langs)
    if(!langs) return
    const langs_view = langs?.map(el => {
        return(
            <div key={el.id} className={s.lang_item} onClick={()=>setLangState(el.id)}>
                <div className={s.lang}>{el.key}</div>
            </div>
        )
    })
    
    return(
        <div className={s.wrapper}>
            <header>
                <div className={s.left}>
                    <div className={s.header_item}>
                        <span className={s.title}>type:</span>
                        <span className={s.value}>{data.title}</span>
                        <span className={s.title}>ID:</span>
                        <span className={s.value}>{data.id}</span>
                        <span className={s.title}>Pseudo:</span>
                        <span className={s.value}>{data.pseudo}</span>
                    </div>
                    {data.type === "link"?
                        <div className={s.header_item}>
                            <span className={s.title}>link:</span>
                            <span className={s.value}>Вставить ссылку</span>
                        </div>
                        :null
                    }
                    <div className={s.lang_block}>
                        {langs_view}
                    </div>
                </div>
                <div className={s.tools}>
                    { data.html ? <span className={s.html_icon}>HTML</span> : null }
                    <span className={s.edit_icon} onClick={()=>setModal({state: true, langs: langs, values: data.value, type: data.type, post_id: data.id, html: data.html})}>{<SettingsIcon/>}</span>
                </div>
            </header>
            <div className={s.content}>
                <p dangerouslySetInnerHTML={{ __html: data.value?.find(el => el.lang_id === langState)?.value }}></p>
            </div>
        </div>
    )
}